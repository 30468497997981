.wrapper {
    margin: 20px 0;
}

.cardWrapper {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 991px) {
    .cardWrapper {
        flex-direction: column;
        align-items: center;
    }
}

.card {
    width: 24%;
    margin-bottom: 1.33%;
    height: auto;
}
@media only screen and (max-width: 991px) {

    .card {
        width: 100%;
    }
}

.space {
    margin-left: 1.33%;
}

.tile {
    padding: 20px 20px 10px;
    border-bottom: 0 !important;
    margin-bottom: 20px !important;
    text-align: center !important;
    font-size: 20px !important;
    font-weight: normal;
}

.img {
    padding: 0 !important;
}

.description {
    font-family: Arial, serif !important;
    font-size: 16px !important;
    overflow: hidden;
    text-align: left;
    max-height: 168px;
}

.buttonWrapper {
    margin-bottom: 40px;
}

.button {
    width: 100%;
}

.buttonDisabled {
    background:#c4c4c4 !important;
    cursor: not-allowed !important;
}