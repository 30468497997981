.wrapper {
    line-height: 18px;
    cursor: pointer;
}

.floatRight {
    float: right;
}

.line:hover {
    background-color: #eaeeef;
}

.iconWrapper {
    width: 12px;
    margin: 0 5px 0 2px;
    display: inline-block;
}