.wrapper {
    display: flex;
    font-family: Arial, serif;
    height: 470px;
}

.cardWrapper {
    width: 30%;
    display: inline-block;
    background-color: rgba(32, 35, 34, 0.75);
    padding: 35px 20px;
    text-align: center;
    height: 470px;
    border-right: 1px solid white;
}

.cardTitle {
    color: white;
    margin: 0;
    padding: 0 0 10px;
    border-bottom: 1px solid white;
    font-weight: normal;
    font-size: 14px;
}

.cardDescription {
    color: white;
    margin: 0;
    padding: 20px 0 0;
    font-size: 20px;
    height: 335px;
    overflow: hidden;
}

.imageWrapper {
    width: 70%;
    display: inline-block;
    height: 470px;
    overflow: hidden;
}

.image {
    padding: 0 !important;
}

.button {
    color: white;
    background-color: rgb(172, 79, 198);
    text-decoration: none;
    font-family: Arial, serif;
    font-size: 16px;
    display: block;
    width: 100%;
    margin-top: 15px;
    padding: 10px 0;
}

.button:hover {
    color: white;
    background-color: rgb(152, 59, 178);
}

.button:active {
    color: white;
    background-color: rgb(182, 89, 208);
}


