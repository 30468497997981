.closeIcon {
    float: right;
}

.contentModal {
    display: flex !important;
}

.contentMessage {
    display: inline;
    font-size: 14px;
    margin-left: 20px;
}