:root {
  /* INDEX CSS */
  --theme-body-bg: #EAEEEF;
  --theme-body-font-color: #000;

  /* HEADER */
  --theme-goal-btn: #F3841D;
  --theme-goal-btn-text: #FFF;
  --theme-header-text-color: #444;
  
  /* INPUTS */
  --theme-input-border: #cacaca;
  --theme-input-color: #000;

  /* LOGIN */
  --theme-btn-bg: #F3841D;;
  --theme-btn-color: #000;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 62.5%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  line-height: 1;
  font-family: Arial, serif !important;
  font-size: 1.8em;
  color: var(--theme-body-font-color);
  background: var(--theme-body-bg);
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  font-family: Arial, serif !important;
}

p {
  line-height: 1.4;
}

button, input, optgroup, select, textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: Arial, serif;
  line-height: inherit;
}

.form-control {
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  margin-bottom: 10px;
  margin: 5px 0 16px;
  padding: 8px;
  color: var(--theme-input-color);
  border: 1px solid var(--theme-input-border);
}
.textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: none;
}
.form-control-label {
  min-width: 55px;
  padding: 4px 0;
  font-size: 1.6rem;
}
.form-control-btn {
  cursor: pointer;
  width: 100%;
  height: 50px;
  right: 10px;
  margin-top: 10px;
  border: none;
  background: var(--theme-btn-bg);
  color: var(--theme-btn-color);
}
.form-control-btn:disabled {
  opacity: 0.6;
}

/* Radio Btn */
.form-control-label.radio,
.form-control-label.checkbox {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
.radio .form-control,
.checkbox .form-control {
  width: 20px;
  height: 20px;
  min-height: 0;
  margin: 0;
  margin-right: 5px;
}
.radio-buttons-container,
.checkbox-buttons-container {
  display: flex;
  flex-flow: wrap;
  padding: 10px 0;
}

/* Messages */
.ui.message {
  margin: 0;
  padding: 10px;
  font-size: 1.6rem;
}